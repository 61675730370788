import * as React from "react"
import { connect } from 'react-redux'
import { graphql } from "gatsby"
import { handleModalBanner } from '../actions/modal'
import { computePhotosCollection } from "../../helpers/photos"

import Layout from "../components/Layout"
import Header from "../components/Header";
import { MainContainer, HeroContainer } from "../containers/MainContainer";
import Photo from '../components/Photo'
import ModalPhoto from '../components/Modal/ModalPhoto'

const IndexPage = (props) => {
  const { 
    modalBanner, 
    handleModalBanner,
    data: {
      hero: {data: {item: {document}}}}
  } = props;
  
  const photos = computePhotosCollection([props.data.heroModal.data.item.document], []);

  return (
    <Layout>
      <Header slug={'index'} />
      <MainContainer>
        <HeroContainer>
          <Photo
            key={`items-${0}`}
            item={document}
            handleModalBanner={handleModalBanner}
            modalBanner={modalBanner}
            index={0}
          />
        </HeroContainer>
        <ModalPhoto images={photos} />
      </MainContainer>
    </Layout>
  )
}

const mapStateToProps = (state) => ({...state.modalBanner});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
export const pageQuery = graphql`
  query IndexPage {
    hero: prismicHomepage {
      data {
        item {
          document {
            ... on PrismicPhoto {
              uid
              data {
                description {
                  html
                  text
                }
                featured
                formats
                legend {
                  html
                  text
                }
                photo {
                  alt
                  copyright
                  localFile {
                    url
                    childImageSharp {
                      fluid(maxWidth: 800, webpQuality: 5) {
                        srcWebp
                      }
                      gatsbyImageData(
                        webpOptions: {
                          quality: 5
                        }, 
                        formats: WEBP, 
                        placeholder: DOMINANT_COLOR, 
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
                body {
                  ... on PrismicPhotoBodyFormat {
                    id
                    items {
                      format {
                        slug
                        document {
                          ... on PrismicFormat {
                            id
                            data {
                              format {
                                html
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPhotoBodyCategoriesFiltres {
                    id
                    items {
                      filter {
                        slug
                        document {
                          ... on PrismicFilter {
                            id
                            data {
                              filter {
                                text
                                html
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    heroModal: prismicHomepage {
      data {
        item {
          document {
            ... on PrismicPhoto {
              uid
              data {
                description {
                  html
                  text
                }
                featured
                formats
                legend {
                  html
                  text
                }
                photo {
                  alt
                  copyright
                  localFile {
                    url
                    childImageSharp {
                      fluid(maxWidth: 1920, webpQuality: 100) {
                        srcWebp
                      }
                      gatsbyImageData(
                        webpOptions: {
                          quality: 100
                        }, 
                        formats: WEBP, 
                        placeholder: DOMINANT_COLOR, 
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
                body {
                  ... on PrismicPhotoBodyFormat {
                    id
                    items {
                      format {
                        slug
                        document {
                          ... on PrismicFormat {
                            id
                            data {
                              format {
                                html
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPhotoBodyCategoriesFiltres {
                    id
                    items {
                      filter {
                        slug
                        document {
                          ... on PrismicFilter {
                            id
                            data {
                              filter {
                                text
                                html
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`